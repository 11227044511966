<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Authority</span>
                <span class="headline" v-else>Create New Authority</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Name*" :readonly="formType === 'UPDATE'" @input="v => { form.name = v ? v.toUpperCase() : undefined}"  v-model="form.name" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="formType === 'UPDATE'">
                                <v-text-field label="New Name*" @input="v => { form.newName = v ? v.toUpperCase(): undefined}"  v-model="form.newName" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
    },
    computed: {
        populatedForm() {
            return {
                name: this.form.name,
                newName: this.form.newName,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            form: {},
            rules: {
                requiredField: v => !!v || "Required field.",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-authority', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    name: undefined,
                    newName: undefined
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    name: this.selectedItem.name,
                    newName: undefined
                }
            }
        }
    }
}
</script>
